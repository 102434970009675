import React, {useEffect} from "react";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/App.css';
import {BrowserRouter, Navigate, Route, Routes, useLocation} from "react-router-dom";
import Home from "./views/Home";
import {LoadingProvider} from "./components/LoadingScreen";
import {useTranslation} from "react-i18next";
import Footer from "./views/Footer";
import PrivacyPolicy from "./views/PrivacyPolicy";
import {logEvent} from "firebase/analytics";
import {analytics} from "./utils/firebase-config";
import Header from "./views/Header";

const AnalyticsTracker = () => {
    const location = useLocation();

    useEffect(() => {
        logEvent(analytics, 'page_view', {
            page_path: location.pathname + location.search,
        });
    }, [location]);

    return null;
};

function App() {
    useTranslation();

    return (
        <LoadingProvider>
            <BrowserRouter>
                <Header/>
                <main>
                    <Routes>
                        <Route path={"/"} element={<Home/>}/>
                        <Route path="/privacy_policy" element={<PrivacyPolicy/>}/>
                        <Route path="*" element={<Navigate to="/"/>}/>
                    </Routes>
                </main>
                <Footer/>
            </BrowserRouter>
        </LoadingProvider>
    );
}

export default App;
