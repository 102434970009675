import React, { useRef, useState, useEffect } from 'react';
import { GoogleMap, DirectionsRenderer, useLoadScript } from '@react-google-maps/api';
import { t } from "i18next";

const libraries = ['places'];

const Map = ({ directions }) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
        libraries,
    });

    const mapRef = useRef(null);
    const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    setMapCenter({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                },
                () => {
                    // Fallback to default location (0, 0) if geolocation fails
                    setMapCenter({ lat: 0, lng: 0 });
                }
            );
        } else {
            // Fallback to default location (0, 0) if geolocation is not supported
            setMapCenter({ lat: 0, lng: 0 });
        }
    }, []);

    if (loadError) {
        return <div>{t('error_loading_google_maps')}</div>;
    }

    if (!isLoaded) {
        return <div>{t('loading')}</div>;
    }

    return (
        <section>
            <h2 className="mb-2 h5">{t('map')}</h2>
            <div className="card">
                <div className="card-body">
                    <GoogleMap
                        mapContainerStyle={{ height: '400px', width: '100%' }}
                        center={mapCenter}
                        zoom={8}
                        onLoad={map => (mapRef.current = { map })}
                    >
                        {directions && <DirectionsRenderer directions={directions} />}
                    </GoogleMap>
                </div>
            </div>
        </section>
    );
};

export default Map;
