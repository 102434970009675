import React from 'react';
import {Link} from "react-router-dom";
import {t} from "i18next";
import { ReactComponent as Logo } from '../assets/logo.svg';


const Header = () => {
    return (
        <header>
            <div className="container">
                <Link to={"/"} className={"logo"}>
                    <Logo className={"logo-img"}/> {t('app_title')}
                </Link>
            </div>
        </header>
    );
}

export default Header;
