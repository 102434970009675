import React, { useRef, useEffect } from 'react';
import { Autocomplete, useLoadScript } from '@react-google-maps/api';
import { t } from "i18next";
import { logEvent } from 'firebase/analytics';
import { analytics } from '../utils/firebase-config'; // Importa Analytics de firebase-config

const libraries = ['places'];

const RouteForm = ({ startAddress, setStartAddress, newAddress, setNewAddress, addAddress }) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
        libraries,
    });

    const startAutocompleteRef = useRef(null);
    const newAutocompleteRef = useRef(null);

    useEffect(() => {
        if (isLoaded && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    const geocoder = new window.google.maps.Geocoder();

                    geocoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
                        if (status === 'OK' && results[0]) {
                            setStartAddress(results[0].formatted_address);
                        } else {
                            console.error('Error al obtener la dirección: ', status);
                        }
                    });
                },
                () => {
                    console.error('Error al obtener la ubicación.');
                }
            );
        }
    }, [isLoaded, setStartAddress]);

    const handlePlaceChanged = (autocompleteRef, setAddress) => {
        const place = autocompleteRef.current.getPlace();
        if (place && place.formatted_address) {
            setAddress(place.formatted_address);
        }
    };

    const handleFocus = () => {
        setStartAddress('');
    };

    const handleAddAddress = () => {
        addAddress();
        logEvent(analytics, 'add_address', {
            address: newAddress,
        });
    };

    if (loadError) {
        return <div>{t('error_loading_google_maps')}</div>;
    }

    if (!isLoaded) {
        return <div>{t('loading')}</div>;
    }

    return (
        <section>
            <h2 className={"mb-2"}>{t('optimize_route')}</h2>
            <div className="card mb-4">
                <div className="card-body">
                    <div className="mb-3">
                        <label htmlFor="startAddress" className="form-label">{t('start_address')}</label>
                        <Autocomplete
                            onLoad={autocomplete => (startAutocompleteRef.current = autocomplete)}
                            onPlaceChanged={() => handlePlaceChanged(startAutocompleteRef, setStartAddress)}
                        >
                            <input
                                type="text"
                                className="form-control"
                                id="startAddress"
                                autoComplete="off"
                                value={startAddress}
                                onFocus={handleFocus}
                                onChange={(e) => setStartAddress(e.target.value)}
                            />
                        </Autocomplete>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="newAddress" className="form-label">{t('add_destination')}</label>
                        <Autocomplete
                            onLoad={autocomplete => (newAutocompleteRef.current = autocomplete)}
                            onPlaceChanged={() => handlePlaceChanged(newAutocompleteRef, setNewAddress)}
                        >
                            <input
                                type="text"
                                className="form-control"
                                id="newAddress"
                                autoComplete="off"
                                value={newAddress}
                                onChange={(e) => setNewAddress(e.target.value)}
                                disabled={!startAddress.trim()}
                            />
                        </Autocomplete>
                    </div>
                    <div className="text-end">
                        <button
                            className="btn btn-primary mt-2"
                            onClick={handleAddAddress}
                            disabled={!startAddress.trim()}
                        >
                            {t('add')}
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default RouteForm;
