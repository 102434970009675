// Función TSP simple usando la heurística del vecino más cercano
const solveTSP = (distances) => {
    const n = distances.length;
    const visited = Array(n).fill(false);
    const path = [0];
    visited[0] = true;

    for (let i = 0; i < n - 1; i++) {
        const last = path[path.length - 1];
        let next = -1;
        let minDist = Infinity;

        for (let j = 1; j < n; j++) {
            if (!visited[j] && distances[last][j] < minDist) {
                next = j;
                minDist = distances[last][j];
            }
        }

        path.push(next);
        visited[next] = true;
    }

    return path;
};

export default solveTSP;
