import React from 'react';
import {t} from "i18next";

function PrivacyPolicy() {
    return (
        <div className="container my-5">
            <h1>{t('privacy_policy')}</h1>
            <h2>{t('introduction')}</h2>
            <p>{t('introduction_msj', {web_name: "NaveganteElcano"})}</p>
            <h2>{t('information_collect')}</h2>
            <p>{t('information_collect_msj')}</p>
            <ul>
                <li>{t('contact_information')}</li>
                <li>{t('demographic_information')}</li>
                <li>{t('usage_information')}</li>
                <li>{t('technical_information')}</li>
            </ul>
            <h2>{t('how_we_use_info')}</h2>
            <p>{t('how_we_use_info_msj')}</p>
            <ul>
                <li>{t('provide_improve_service')}</li>
                <li>{t('communication')}</li>
                <li>{t('display_ads')}</li>
            </ul>
            <h2>{t('cookies_tracking')}</h2>
            <p>{t('cookies_tracking_msj')}</p>
            <h2>{t('advertising')}</h2>
            <p>{t('advertising_msj1')}</p>
            <p>{t('advertising_msj2')}</p>
            <h2>{t('how_we_share_info')}</h2>
            <ul>
                <li>{t('service_providers')}</li>
                <li>{t('ad_partners')}</li>
                <li>{t('legal_authorities')}</li>
            </ul>
            <h2>{t('security')}</h2>
            <p>{t('security_msj')}</p>
            <h2>{t('your_privacy_rights')}</h2>
            <p>{t('your_privacy_rights_msj')}</p>
            <h2>{t('changes_to_policy')}</h2>
            <p>{t('changes_to_policy_msj')}</p>
            <h2>{t('contact_title')}</h2>
            <p>{t('contact_msj', {email_contact: "piffaut.jean@gmail.com"})}</p>
        </div>
    );
}

export default PrivacyPolicy;
