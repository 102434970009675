import React from 'react';
import { t } from "i18next";
import { logEvent } from 'firebase/analytics';
import { analytics } from '../utils/firebase-config'; // Importa Analytics de firebase-config

const AddressList = ({ addresses, removeAddress }) => {
    const handleRemoveAddress = (index) => {
        logEvent(analytics, 'remove_address', {
            address: addresses[index],
        });
        removeAddress(index);
    };

    return (
        <section>
            <h2 className="mb-2 h5">{t('address_list')}</h2>
            {addresses.length === 0 ? (
                <div className={"list-group mb-3"}>
                    <p className="list-group-item text-center">{t('no_destinations_added')}</p>
                </div>
            ) : (
                <ul className="list-group mb-3">
                    {addresses.map((address, index) => (
                        <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                            <p>{address}</p>
                            <button className="btn btn-danger btn-sm" onClick={() => handleRemoveAddress(index)}>
                                <i className="fa-solid fa-trash"></i>
                            </button>
                        </li>
                    ))}
                </ul>
            )}
        </section>
    );
};

export default AddressList;
