import React, {useState, useEffect, useCallback} from 'react';
import RouteForm from '../components/RouteForm';
import AddressList from '../components/AddressList';
import Map from '../components/Map';
import solveTSP from '../utils/tspSolver';
import {t} from "i18next";
import JsonLd from "../components/JsonLd";
import MetaTags from "../components/MetaTags";

const Home = () => {
    const [startAddress, setStartAddress] = useState('');
    const [addresses, setAddresses] = useState([]);
    const [newAddress, setNewAddress] = useState('');
    const [directions, setDirections] = useState(null);
    const [routeUrl, setRouteUrl] = useState('');


    const addAddress = () => {
        if (newAddress.trim() !== '') {
            setAddresses((prevAddresses) => {
                const updatedAddresses = [...prevAddresses, newAddress.trim()];
                optimizeRoute(startAddress, updatedAddresses);
                return updatedAddresses;
            });
            setNewAddress('');
        }
    };

    const removeAddress = (index) => {
        setAddresses((prevAddresses) => {
            const updatedAddresses = prevAddresses.filter((_, i) => i !== index);
            optimizeRoute(startAddress, updatedAddresses);
            return updatedAddresses;
        });
    };

    const optimizeRoute = useCallback((start, destinations) => {
        if (!start.trim()) {
            return;
        }

        if (destinations.length === 0) {
            setDirections(null);
            setRouteUrl('');
            return;
        }

        const service = new window.google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
            {
                origins: [start, ...destinations],
                destinations: [start, ...destinations],
                travelMode: window.google.maps.TravelMode.DRIVING,
                unitSystem: window.google.maps.UnitSystem.METRIC,
            },
            (response, status) => {
                if (status !== 'OK') {
                    console.error('Error fetching distance matrix:', status);
                    return;
                }

                const distances = response.rows.map(row => row.elements.map(el => el.distance.value));
                const optimalOrder = solveTSP(distances);
                const orderedDestinations = optimalOrder.slice(1).map(index => destinations[index - 1]);

                const waypoints = orderedDestinations.slice(0, -1).map((address) => ({
                    location: address,
                    stopover: true
                }));
                const directionsService = new window.google.maps.DirectionsService();

                directionsService.route(
                    {
                        origin: start,
                        destination: orderedDestinations[orderedDestinations.length - 1],
                        waypoints: waypoints,
                        travelMode: window.google.maps.TravelMode.DRIVING,
                        optimizeWaypoints: false,
                    },
                    (result, status) => {
                        if (status === window.google.maps.DirectionsStatus.OK) {
                            setDirections(result);
                            const routeUrl = generateGoogleMapsUrl(result);
                            setRouteUrl(routeUrl);
                        } else {
                            console.error(`Error fetching directions: ${status}`);
                            alert(t('no_destinations_added'));
                        }
                    }
                );
            }
        );
    }, []);

    const generateGoogleMapsUrl = (result) => {
        const baseUrl = 'https://www.google.com/maps/dir/';
        const route = result.routes[0].legs.map((leg) => leg.start_address).join('/');
        return `${baseUrl}${route}/${result.routes[0].legs.slice(-1)[0].end_address}`;
    };

    useEffect(() => {
        optimizeRoute(startAddress, addresses);
    }, [startAddress, addresses, optimizeRoute]);

    const basicInfo = {
        type: "WebPage",
        url: process.env.PUBLIC_URL,
        name: t('app_long_title'),
        description: t('app_description'),
        keywords: t('keywords')
    };

    const extraInfo = {
        author: {
            "@type": "Person",
            "name": "Jean Piffaut"
        }
    };

    return (
        <div className="container py-5">
            <JsonLd basicInfo={basicInfo} extraInfo={extraInfo} />
            <MetaTags
                title={t('app_long_title')}
                description={t('app_description')}
                image={`${process.env.PUBLIC_URL}/static/media/meta-image.png`}
                url={process.env.PUBLIC_URL}
            />
            <h1 className="text-center">NaveganteElcano</h1>
            <section className="mb-4">
                <h2 className="mb-2">{t('what_is_this')}</h2>
                <p>{t('app_description')}</p>
                <p>{t('google_maps_api_usage')}</p>
            </section>

            <section className="mb-4">
                <h2 className="mb-2">{t('how_it_works')}</h2>
                <ul style={{ listStyleType: 'numbers' }}>
                    <li>
                        <p>{t('step_1')}</p>
                    </li>
                    <li>
                        <p>{t('step_2')}</p>
                    </li>
                    <li>
                        <p>{t('step_3')}</p>
                    </li>
                    <li>
                        <p>{t('step_4')}</p>
                    </li>
                </ul>
            </section>
            <RouteForm
                startAddress={startAddress}
                setStartAddress={setStartAddress}
                newAddress={newAddress}
                setNewAddress={setNewAddress}
                addAddress={addAddress}
            />
            <AddressList
                addresses={addresses}
                removeAddress={removeAddress}
            />
            <Map directions={directions}/>
            {routeUrl && (
                <section>
                    <h2 className={"mb-2 h5"}>{t('optimized_route_url')}</h2>
                    <div className="card">
                        <div className="card-body">
                            <a href={routeUrl} target="_blank" rel="noopener noreferrer">{routeUrl}</a>
                        </div>
                    </div>
                </section>
            )}
        </div>
    );
};

export default Home;
