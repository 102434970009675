import React from 'react';
import {useTranslation} from 'react-i18next';
import {t} from "i18next";

function LanguageSwitcher() {
    const {i18n} = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language).then(() => {
            const html = document.documentElement;
            html.setAttribute('lang', language);
            document.title = t('app_long_title');
            document.description = t('app_description');
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        });
    };


    const currentLang = i18n.language;

    return (
        <div>
            {currentLang === 'en' ? (
                <button type={"button"} aria-label={t('change_language')} className={"btn btn-dark text-white"}
                        onClick={() => changeLanguage('es')}>ES</button>
            ) : (
                <button type={"button"} aria-label={t('change_language')} className={"btn btn-dark text-white"}
                        onClick={() => changeLanguage('en')}>EN</button>
            )}
        </div>
    );
}

export default LanguageSwitcher;